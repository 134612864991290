<template>
  <mad-lib-input :value="[value]"
                 :height="height"
                 :format="format"
                 :type-ahead-options="typeAheadOptions"
                 :placeholder="placeholder"
                 :allow-empty-strings="allowEmptyStrings"
                 single-input
                 :disable-auto-focus="disableAutoFocus"
                 :retain-value-on-focus="retainValueOnFocus"
                 :fill-width="useFillWidth ? fillWidth : false "
                 :taggify="taggify"
                 :taggify-placeholder-text="taggifyPlaceholderText"
                 :badgify="badgify"
                 :can-create-aliases="canCreateAliases"
                 :can-mark-as-required="canMarkAsRequired"
                 :subtle="subtle"
                 :textarea-rows="textareaRows"
                 :character-limit="characterLimit"
                 :length-error="lengthViolationMsg"
                 :error="error"
                 :disabled="disabled"
                 :custom-text-formatter="customTextFormatter"
                 :require-option="requireOption"
                 :drop-down-right="dropDownRight"
                 :text-variant="textVariant"
                 :class="{ 'w-100 mw-100' : useFillWidth && fillWidth}"
                 :input-id="inputId"
                 :type-ahead-loading="typeAheadLoading"
                 :paging-size="pagingSize"
                 :caret="caret"
                 :block="block"
                 :overflowEllipsis="overflowEllipsis"
                 :min-currency-value="minCurrencyValue"
                 @type="$emit('type', $event)"
                 @input="update($event)"
                 @focus="focus"
                 @blur="blur">
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData || {}" :name="name" />
    </template>
  </mad-lib-input>
</template>
<script>
import MadLibInput from 'core-ui/components/inputs/madLibInput.vue'
export default {
  // //////////////////////
  /// A wrapper that makes using the mad lib input component easy!
  // //////////////////////
  name: 'BasicMadLibInput',
  components: { MadLibInput },
  props: {
    value: {
      type: [String, Number, Boolean, Object]
    },
    format: {
      type: String,
      default: 'TEXT'
    },
    textareaRows: {
      type: Number,
      default: 5
    },
    typeAheadOptions: {
      type: Array
    },
    placeholder: {
      type: String
    },
    height: {
      type: String,
      default: 'auto'
    },
    maxWidth: {
      type: String,
      default: 'none'
    },
    fillWidth: {
      type: Boolean,
      default: false
    },
    taggify: {
      type: Boolean,
      default: false
    },
    taggifyPlaceholderText: {
      type: Boolean,
      default: true
    },
    badgify: {
      type: Boolean,
      default: true
    },
    canCreateAliases: {
      type: Boolean,
      default: false
    },
    canMarkAsRequired: {
      type: Boolean,
      default: false
    },
    subtle: {
      type: Boolean,
      default: false
    },
    characterLimit: {
      type: Number,
      default: 0
    },
    allowEmptyStrings: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customTextFormatter: {
      type: Function
    },
    retainValueOnFocus: {
      type: Boolean,
      default: true
    },
    requireOption: {
      type: Boolean,
      default: false
    },
    disableAutoFocus: {
      type: Boolean,
      default: true
    },
    dropDownRight: {
      type: Boolean,
      default: false
    },
    textVariant: {
      type: String
    },
    inputId: {
      type: String
    },
    typeAheadLoading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    pagingSize: {
      type: Number
    },
    caret: {
      type: Boolean,
      default: false
    },
    block: {
      // show a MadLib in a b-form-group to look more like a traditional input (with possibility of using MadLib typeahead functionality)
      type: Boolean,
      default: false
    },
    overflowEllipsis: {
      type: Boolean,
      default: false
    },
    minCurrencyValue: {
      type: Number
    }
  },
  emits: ['update:value', 'update:modelValue', 'input', 'focus', 'blur'],
  data () {
    return {
      useFillWidth: false
    }
  },
  methods: {
    update ([value]) {
      this.$emit('update:value', value)
      this.$emit('update:modelValue', value)
      this.$emit('input', value)
    },
    focus () {
      this.useFillWidth = true
      this.$emit('focus')
    },
    blur () {
      this.useFillWidth = false
      this.$emit('blur')
    }
  },
  computed: {
    lengthViolationMsg () {
      if (this.characterLimit && this.characterLimit > 0 && this.value) {
        const actualLength = this.$filters.evaluateLengthAfterInsertion(this.value)
        if (typeof this.value === 'string' && actualLength > this.characterLimit) {
          return `(${actualLength} / ${this.characterLimit})`
        }
      }
      return ''
    }
  }
}
</script>
